.title {
  width: 7rem;
  height: 1.3rem;
  margin: 0.8rem 0;
}
.subtitle1 {
  width: 4rem;
  height: 1.3rem;
  margin: 0.8rem 0;
}
.image {
  width: 100%;
  height: 20vh;
  animation-delay: 700ms !important;
}
.subtitle2 {
  width: 6rem;
  height: 1.3rem;
  margin: 0.7rem 0 0.3rem;
}

.loader {
  background: linear-gradient(to right, #22212105, #22212105);
  position: relative;
  overflow: hidden;
}
.loader::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #22212105, #22212105);
  animation-name: loading;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-80%);
  }
  100% {
    translate: translateX(0%);
  }
}
