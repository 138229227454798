
.cont {
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.967),
    rgba(196, 182, 182, 0.534)
  );
  background-position: initial;
  background-size: cover;
  color: #222326;
  height: 100vh;
}

.col-6-faded {
  background-color: #ffffffcc;
  border-left: 3px solid #22232655;
}

@media only screen and (max-width: 768px) {
  .cont {
    overflow: hidden;
    background: linear-gradient(
      rgba(255, 255, 255, 0.967),
      rgba(38, 32, 32, 0.434)
    );
    background-position: top;
    background-size: cover;
    color: #222326;
    height: 100vh;
  }

  .col-md-6 {
    align-self: center !important;
  }
  .h1 {
    font-size: 25px;
  }
  img {
    width: 100%;
  }
}

.heading-comingSoon {
  font-size: 6rem;
  padding: 2px 20px;
}
.body-comingSoon {
  font-size: 4rem;
  padding: 2px 20px;
}
@media screen and (max-width: 786px) {
  .heading-comingSoon {
    font-size: 3rem;
    padding: 5px 20px;
  }
  .body-comingSoon {
    font-size: 2rem;
    padding: 5px 20px;
  }
}
.container-fluid-a {
  padding: 7% 15%;
}

.blog-btn {
  padding: 0px 10px 0 17px;
  width: 60px;
  border-radius: 50%;
  height: 60px;
  cursor: pointer;
}
.blog-btn:hover {
  background: #ffdd67;
}
