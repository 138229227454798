html {
  font-size: calc(0.75vh + 0.75vw);
  overflow-x: hidden;
}
* {
  font-family: "Montserrat", sans-serif !important;
}

@media screen and (max-width: 786px) {
  html {
    font-size: calc(1.2vh + 1.2vw);
  }
  .about-content-home {
    background: #fafafa;
  }
}
button {
  outline: none !important;
}
body {
  overflow-x: hidden;
  padding-top: 5.5rem;
}

.bg-black-faded {
  background-color: #222326 !important;
}
.txt-black-faded {
  color: #222326;
}

.bg-yellow-primary {
  background-color: #e3afbc !important;
}
.txt-yellow-primary {
  color: #e3afbc;
}

.txt-faded-primary {
  color: #606062;
}

.bg-faded-primary {
  background-color: #606062 !important;
}

.leave-bg {
  background: url("./img/leaves-bg.png");
  background-position: center;
  background-size: cover;
  position: relative;
  min-height: 70vh;
}
.leave-bg div ~ div > img {
  margin: 0.5rem;
  max-width: 70vw;
  box-shadow: #00000040 -4px -4px 9px 1px, #00000020 1px 1px 40px 10px;
}
.leave-bg > div ~ div > img:nth-child(1) {
  border-radius: 5rem 0 0 0;
}
.leave-bg > div ~ div > img:nth-child(2) {
  border-radius: 0 0 5rem 0;
}
.toggleButton50 {
  width: 10.4rem;
}
.toggleButton100 {
  width: 20.8rem;
}
.works-card {
  min-height: 24vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: white;
  border-radius: 1rem;
  transition: 0.4s;
  box-shadow: #00000030 2px 2px 5px 3px;
}

.works-card > img {
  margin: 1rem;
  height: 4rem;
}
.works-card > p {
  min-height: 10vh;
  font-size: 1rem;
}

.works-card:hover {
  transform: scale(1.05);
}
.btn-outline-change {
  color: #222326;
  border: none;
  outline: none;
  padding: 0.25rem;
  font-size: 0.8rem;
  transition: 0.3s;
  background-color: #fefefe;
  border-radius: 0.25rem;
}
.btn-outline-change:active {
  border: none;
}
.about-content-home {
  letter-spacing: 1px;
  position: relative;
}
.about-content-home > img {
  position: absolute;
  width: 60%;
  right: -30%;
  top: 0;
}

.btn-outline-change:hover {
  border: solid #e3afbc 1px;

  color: #e3afbc;
}
.about-us {
  width: 80vw;
  margin: auto;
  color: #001b2e;
}

.about-heading {
  font-weight: 700;
}

.about-hr {
  width: 5vw;
  margin-left: auto;
  margin-right: auto;
  padding: 2px;
  /* color: #001B2E; */
  background-color: #001b2e;
}

@media only screen and (max-width: 600px) {
  .about-img {
    visibility: hidden;
  }
}

.bg-darker {
  background: #1b1c1f !important;
}
.bg-light {
  background: #fafaf9 !important;
}

.bg-white {
  background: white !important;
}
.h-90 {
  height: 92vh !important;
}
.h-80 {
  height: 80vh !important;
}
.carousel {
  overflow: hidden !important;
  text-align: center;
  /* height: 40vh; */
}
.text-dark {
  color: #4a4a4a !important;
}
.author-links {
  width: 30px !important;
  height: 30px !important;
  padding: 5px !important;
}

.full-height {
  height: 100vh !important;
}

.threeQuater-height {
  height: 75vh !important;
}

.half-height {
  height: 50vh !important;
}

.quater-height {
  height: 25vh !important;
}

.btn,
.jumbotron,
.nav :hover {
  border-radius: 0px !important;
}

.blog-intro {
  background: #ffffff;
  height: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.white-overlay {
  background-color: #ffcd38;
}

.post-side-img {
  width: 70px !important;
  height: 70px !important;
  object-fit: cover;
}

.author-thumb {
  width: 90px;
  height: 90px !important;
  object-fit: cover;
}

.post-author {
  font-size: 12px !important;
}

.side-pen {
  font-size: 12px !important;
}
body {
  background: #fafafa;

  color: black !important;
}

/* footer{
  position: absolute;
  bottom: 0px;
  right: 0;
  left: 0;
} */

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

#header {
  height: 110px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  padding: 20px 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
}

#header.header-scrolled,
#header.header-pages {
  height: 70px;
  padding: 15px 0;
  background-color: #222326;
}

#header.header-scrolled #topbar,
#header.header-pages #topbar {
  display: none;
}

#header .logo h1 {
  font-size: 36px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #413e66;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 7px 0;
  max-height: 26px;
}

.main-pages {
  margin-top: 60px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/* Desktop Navigation */

.main-nav,
.main-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav > ul > li {
  position: relative;
  white-space: nowrap;
  float: left;
}

.main-nav a {
  display: block;
  position: relative;
  color: #413e66;
  padding: 10px 15px;
  transition: 0.3s;
  font-size: 14px;

  text-transform: uppercase;
  font-weight: 600;
}

.main-nav a:hover,
.main-nav .active > a,
.main-nav li:hover > a {
  color: #e3afbc;
  text-decoration: none;
}

.main-nav .drop-down ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% - 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: ease all 0.3s;
}

.main-nav .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.main-nav .drop-down li {
  min-width: 180px;
  position: relative;
}

.main-nav .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #065e77;
}

.main-nav .drop-down ul a:hover,
.main-nav .drop-down ul .active > a,
.main-nav .drop-down ul li:hover > a {
  color: #1bb1dc;
}

.main-nav .drop-down > a:after {
  content: "\f107";
  font-family: FontAwesome;
  padding-left: 10px;
}

.main-nav .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.main-nav .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.main-nav .drop-down .drop-down > a {
  padding-right: 35px;
}

.main-nav .drop-down .drop-down > a:after {
  content: "\f105";
  position: absolute;
  right: 15px;
}

/* Mobile Navigation */

.mobile-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
  left: -260px;
  width: 260px;
  padding-top: 18px;
  background: rgba(40, 38, 70, 0.8);
  transition: 0.4s;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
}

.mobile-nav a:hover,
.mobile-nav .active > a,
.mobile-nav li:hover > a {
  color: #8dc2fa;
  text-decoration: none;
}

.mobile-nav .drop-down > a:after {
  content: "\f078";
  font-family: FontAwesome;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down > a:after {
  content: "\f077";
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  margin: 18px 18px 0 0;
  color: #065e77;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(40, 38, 70, 0.8);
  overflow: hidden;
  display: none;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  left: 0;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

#logohead {
  max-height: 70px !important;
}

/*three-ques*/
.three-ques {
  background: #ffcd38;
}
@media (min-width: 1200px) {
  .marginextra {
    margin-top: 400px;
  }
}

@media (max-width: 764px) {
  .mobile-nav-toggle {
    margin-top: 15px;
    color: white;
  }
  #logohead {
    max-height: 70px !important;
    margin-top: -5px !important;
  }
}

/*/////////////////////////////////////////////////////////////
                  Site Footer
//////////////////////////////////////////////////////////////*/
.site-footer {
  background-color: #141304;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
}
.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}
.site-footer hr.small {
  margin: 20px 0;
}
.site-footer h6 {
  color: #f5eae7;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}
.site-footer a {
  color: #737373;
}
.site-footer a:hover {
  color: #9a1750;
  text-decoration: none;
}
.footer-links {
  padding-left: 0;
  list-style: none;
}
.footer-links li {
  display: block;
}
.footer-links a {
  color: #737373;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #9a1750;
  text-decoration: none;
}
.footer-links.inline li {
  display: inline-block;
}
.site-footer .social-icons {
  text-align: right;
}
.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d;
  color: #fefefe;
}
.copyright-text {
  margin: 0;
}
@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0;
  }
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}
.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}
.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px;
}
.social-icons a {
  color: #2c2c2c;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #222326;
  background-color: #29aafe;
}
.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}
.social-icons a.facebook:hover {
  background-color: #4267b2;
}
.social-icons a.whatsapp:hover {
  background-color: #25d366;
}
.social-icons a.twitter:hover {
  background-color: #00acee;
}
.social-icons a.linkedin:hover {
  background-color: #0077b5;
}
.social-icons a.instagram:hover {
  background-color: #cd486b;
}
.sl-facebook:hover {
  background-color: #4267b2 !important;
}
.sl-whatsapp:hover {
  background-color: #25d366 !important;
}
.sl-twitter:hover {
  background-color: #00acee !important;
}
.sl-linkedin:hover {
  background-color: #0077b5 !important;
}
.sl-instagram:hover {
  background-color: #cd486b !important;
}

@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}

/*/////////////////////////////////////////////////////////
          blog card content 
///////////////////////////////////////////////////////*/
.content-section {
  padding-top: 10.5rem;
  padding-bottom: 7.5rem;
}

.content-section-heading h2 {
  font-size: 3rem;
}

.content-section-heading h3 {
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 50px;
}

.portfolio-item {
  height: 330px;
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 530px;
  margin: auto auto 1rem;
}

.portfolio-item .caption {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(8, 5, 5, 0.87);
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.portfolio-item .caption .caption-content {
  color: #fff;
  margin: auto 2rem 2rem;
}

.portfolio-item .caption .caption-content h2 {
  font-size: 1.3rem;
  text-transform: uppercase;
  color: #fcd4c9;
  font-weight: bolder;
  letter-spacing: 1px;
}
.portfolio-item:hover .caption .caption-content h2 {
  font-weight: 999;
  font-size: 1.5rem;
  color: #4a4a4a;
}

.portfolio-item .caption .caption-content p {
  font-weight: 200;
  font-size: 0.9rem;
  min-height: 70px;
}
.portfolio-item:hover .caption .caption-content p {
  color: #4a4a4a;
}

.portfolio-item img {
  object-fit: cover !important;
  height: 100% !important;
  width: 100%;
}
@media (min-width: 992px) {
  .portfolio-item {
    max-width: none;
    margin: 0;
  }
  .portfolio-item .caption {
    -webkit-transition: -webkit-clip-path 0.35s ease-out, background-color 0.7s;
    -webkit-clip-path: inset(0px);
    clip-path: inset(0px);
  }
  .portfolio-item .caption .caption-content {
    transition: opacity 0.25s;
  }
  .portfolio-item img {
    -webkit-transition: -webkit-clip-path 0.35s ease-out;
    -webkit-clip-path: inset(-1px);
    clip-path: inset(-1px);
  }
  .portfolio-item:hover img {
    -webkit-clip-path: inset(2rem);
    clip-path: inset(0.5rem);
  }
  .portfolio-item:hover .caption {
    background-color: rgba(245, 252, 255, 0.719);
    -webkit-clip-path: inset(2rem);
    clip-path: inset(0.5rem);
  }
}

/*        Blogs Banner */
#blogBanner {
  background-color: #9a1750 !important;
  background-size: "cover";
  height: 70vh;
}

/*         Each Blog  */
#eachBlogHeader {
  background-color: #e3afbc !important;
  background-size: "cover";
  padding-top: 10vh !important;
}

/*       Portfolio other       */
@media only screen and (max-width: 600px) {
  #blogBanner {
    font-size: medium;
  }
  #eachBlogHeader {
    font-size: x-large;
  }
  #eachBlogSubtitle {
    padding: 0 !important;
  }
  #eachBlogSubtitleHeading {
    padding: 0 !important;
  }
  #contentCard {
    padding-right: 0 !important;
  }
  #contentCardRow {
    padding-top: 0.5vh;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  #contentCardCol {
    padding-right: 0 !important;
  }
  .about-author {
    margin-top: 0px;
  }
  #blogDescription {
    font-size: medium;
  }
  .about-author {
    margin-top: 1vh !important;
    margin-bottom: 6vh !important;
  }
}

/*/////////////////////////////////////////////////////////
          Scroll Bar
////////////////////////////////////////////////////////////*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #e3e2df;
}

::-webkit-scrollbar-thumb {
  background: #ee4c7c;
}

#side-menu {
  z-index: 99999;
}

.nav-link.active {
  color: #e3afbc;
}

.nav-link {
  font-size: 0.65rem;
  padding: 10px;
  text-transform: uppercase !important ;
  letter-spacing: 1px !important;
  font-weight: bold !important;
  color: #e3e2df;
}
.nav-link:hover {
  color: #ffb534;
}
.post-side-img {
  height: 70px !important;
  width: 74px !important;
  object-fit: cover !important;
}

nav {
  background-color: #fff;
  /* background-color: #18110E; */
  /* box-shadow: #714838 0px 0px 7px 3px; */
  border: none;
}

.header-padding {
  padding-top: 0px !important;
}

#sidebar {
  position: absolute;
  height: 100vh;
  width: 70vw;
  left: 0;
  top: 0;
  text-align: center;
  padding-top: 1vh;
  animation: fadeRight 0.5s;
  background-color: #18110e;
}
.fadeRightAnim {
  animation: fadeRight 1s !important;
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeLeftAnim {
  animation: fadeLeft 1s !important;
}

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInAnim {
  animation: fadeIn 0.9s !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.mob-link {
  font-size: 5vw !important;
}

/*/////////////////////////////////////////////////////////////////////
                Newsletter
/////////////////////////////////////////////////////////////////////*/
.newsletter {
  padding: 80px 0;
  background: #f2f2f2;
}

.newsletter .content {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
}
.newsletter .content h2 {
  color: #243c4f;
  margin-bottom: 40px;
}
.newsletter .content .form-control {
  height: 50px;
  border-color: #ffffff;
  border-radius: 0;
}
.newsletter .content.form-control:focus {
  box-shadow: none;
  border: 2px solid #243c4f;
}
.newsletter .content .btn {
  min-height: 50px;
  border-radius: 0;
  background: #243c4f;
  color: #fff;
  font-weight: 600;
}

hr.divider {
  max-width: 3.25rem;
  border-width: 0.2rem;
  border-color: #9a1750;
}

.contact-a {
  text-decoration: none !important;
  background-color: transparent !important;
}

.contact-a-capitalize {
  text-transform: capitalize;
}

.contact-a:hover {
  color: #222326d0 !important;
}
.navbar {
  z-index: 99999 !important;
}
/*/////////////////////////////////////////////////////////
          Rahul
/////////////////////////////////////////////////////////////*/
.q-tut-card {
  background-color: #fff6f5c7 !important;
  min-height: 220px;
  padding: 20px !important;
  border-top-right-radius: 30%;
}

.questionaire-home {
  background: radial-gradient(#9a185005, #9a185010);
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  min-height: 100vh;
  width: 100% !important;
}

.formButton:hover {
  background-color: #000 !important;
  color: #fff !important;
}

/*//////////////////////////////////////////////////////////
        Map
////////////////////////////////////////////////////////////*/

/* Map */
.map {
  height: inherit;
}

@media (max-width: 992px) {
  .map {
    height: inherit;
  }
}

.map iframe {
  height: 100%;
  width: 100%;
  border: 0;
}

/*////////////////////////////////////////////////////////////
              Contact Social links
////////////////////////////////////////////////////////////*/

section.social {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

section.social .social-link {
  display: block;
  height: 3rem;
  width: 3rem;
  line-height: 3rem;
  font-size: 1.5rem;
  background-color: #1d809f;
  transition: background-color 0.15s ease-in-out;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

section.social .social-link:hover {
  background-color: #155d74;
  text-decoration: none;
}

/*////////////////////////////////////////////////////////////
              About Timeline
////////////////////////////////////////////////////////////*/

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}
.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: "";
  background-color: #e9ecef;
}
.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}
.timeline > li:after,
.timeline > li:before {
  display: table;
  content: " ";
}
.timeline > li:after {
  clear: both;
}
.timeline > li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}
.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}
.timeline > li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #e9ecef;
  border-radius: 100%;
  background-color: #fed136;
}
.timeline > li .timeline-image h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}
.timeline > li:last-child {
  margin-bottom: 0;
}
.timeline .timeline-heading h4 {
  margin-top: 0;
  color: inherit;
}
.timeline .timeline-heading h4.subheading {
  text-transform: none;
}
.timeline .timeline-body > ul,
.timeline .timeline-body > p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }
  .timeline > li {
    min-height: 100px;
    margin-bottom: 100px;
  }
  .timeline > li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }
  .timeline > li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }
  .timeline > li .timeline-image h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }
  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }
  .timeline > li .timeline-image h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}
@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px 100px;
  }
  .timeline > li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }
  .timeline > li .timeline-image h4 {
    margin-top: 40px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

.team-member {
  margin-bottom: 3rem;
  text-align: center;
}
.team-member img {
  width: 14rem;
  height: 14rem;
  border: 0.5rem solid rgba(0, 0, 0, 0.1);
}
.team-member h4 {
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.percent-text {
  color: black !important;
}

.tagInputFieldClass {
  padding: 5px;
  width: 100%;
  margin: 10px 0 20px 0;
}
.tagInputFieldClass::before {
  content: "Tags : ";
}
.tagClass {
  padding: 2px;
  color: #000 !important;
  border: 1px solid #c29958;
  margin-right: 5px;
}

.cardCheckout {
  border: 1px solid #9a1750;
  border-radius: 3px;
  z-index: 20;
}

.intro-text {
  padding: 1rem 10%;
  text-align: center;
}
.intro-text h1 {
  margin: 0;
  font-size: 4.5vh;
}
.intro-text p {
  font-size: 2.5vh;
}

.hvr-grow {
  /* color: '#FFFFFF'; */
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.4s !important;
  transition-duration: 0.4s !important;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  opacity: 0.8 !important;
  transform: scale(1.1);
  /* border: 1px solid #fff !important; */
}

a {
  text-decoration: none !important;
}

/*//////////////////////////////////////////////////////////////////////////////////////////
                            Animations
//////////////////////////////////////////////////////////////////////////////////////////*/
@keyframes animateOtp {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes animateRotate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.nextPageQuestionnaire {
  position: absolute !important;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  border-radius: 50% !important;
  height: 3.5rem !important;
  text-align: center !important;
  width: 3.5rem !important;
  padding: 0 !important;
  min-width: 0 !important;
  min-height: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: white !important;
}
.nextPageQuestionnaire:disabled {
  background: #808080 !important;
}
.prevPageQuestionnaire {
  position: absolute !important;
  top: 51%;
  left: 0;
  transform: translate(-50%, -50%);
  border-radius: 50% !important;
  height: 3.5rem !important;
  text-align: center !important;
  width: 3.5rem !important;
  padding: 0 !important;
  min-width: 0 !important;
  min-height: 0 !important;
  color: white !important;
  font-weight: 800 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.prevPageQuestionnaire:disabled {
  background: transparent !important;
}

.card-features {
  padding: 1rem;
  min-height: 20vh;
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: center;
  border-radius: 0.5rem;
  transition: 0.2s;
}
.card-features:hover {
  transform: scale(1.05);

  box-shadow: 1px 1px 10px 2px #be961320;
}
.card-features p {
  font-size: 0.9rem;
  color: #ddd;
  font-weight: 500;
}

.card-features img {
  width: 5rem;
  margin-right: 1.2rem;
}
.text-last-center {
  text-align-last: center !important;
}
.testimonial-card {
  box-shadow: 1px 1px 3px #80808080;
  min-height: 200px;
  margin: 20px 0;
}
.testimonial-card:hover {
  cursor: pointer;
}

.about-who-container {
  margin-bottom: 9rem;
}

.faq-question {
  color: #222222;
  font-size: 1.3rem;
  font-weight: 500;
}

.subscription {
  width: 100%;
  padding: 1rem 5%;
  text-align: center;
  /* background-color: #222326; */
}

.subscriptionCardBox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 7rem;
  row-gap: 0.5rem;
}

.subscriptionCardInner {
  display: inline-block;
  margin: 5%;
  height: 20rem;
  border-radius: 10px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 0.5rem;
  box-shadow: 10px 5px 20px 1px #47484950;
}

.subscriptionCardInner:hover {
  margin: 0;
  background-color: #9a1750 !important;
}

.subscriptionCardInner .Months {
  justify-self: center;
  align-self: center;
}

.subscriptionCardInner .Months p {
  font-size: 1.5rem;
  font-weight: 900;
  color: azure;
  opacity: 0.4;
  margin: 0;
}
.subscriptionCardInner.active .Months p {
  color: #fff !important;
  opacity: 0.9;
}

.subscriptionCardInner .details hr {
  border: 1px solid #f2f2f2;
  margin: auto;
  margin-top: -5rem;
  width: 80%;
}

.subscriptionCardInner .details p span {
  font-size: 2rem;
  font-weight: 500;
}

.subscriptionCardInner.active .details p span {
  color: #fff !important;
  opacity: 0.9;
}

.subscriptionCardInner .Months p span {
  font-size: 3.5rem;
}

.subscriptionCardInner .details {
  justify-self: center;
  align-self: center;
}

.subscriptionCardInner .details p {
  font-size: 1.3rem;
  font-weight: 500;
  color: azure;
  opacity: 0.7;
  margin: 1rem 0;
}
.subscriptionCardInner.active .details p {
  opacity: 1;
}

.subscriptionCardInner .details button {
  padding: 1rem 2rem;
  color: white;
  background-color: #ee4c7c;
  border-radius: 5px;
  border: none;
  /* font-weight: bold; */
  /* box-shadow: 5px 2px #e3afbc; */
}

.subscriptionCardInner .details button:disabled {
  background-color: #222326d0;
}

@media screen and (max-width: 780px) {
  .about-who-container {
    margin-bottom: 1rem !important;
  }

  .text-sm-justify {
    text-align: justify !important;
  }
  .subscriptionCardBox {
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    display: none;
  }
}

/*//////////////////////////////
Why circle
///////////////////////////////////////////*/

.relDisplay {
  position: relative;
}

.absoluteimage {
  position: absolute;
  left: 0;
  top: -3rem;
}

.absoluteImageBottom {
  position: absolute;
  left: 0;
  bottom: 0;
}

.highzindex {
  z-index: 999;
}

/*///////////////////////////////////////////////////
              Data Dictionary
///////////////////////////////////////////////////*/

.dictionaryEffects {
  font-size: 0.65rem;
  color: #ffffff;
}

.effectsChip {
  background-color: #ee4c7c;
  margin: 0.12rem;
  padding: 0.2rem;
  border-radius: 0.25rem;
  display: inline-block;
}

.researchImage {
  border-radius: 0rem 22.24rem 0rem 0rem;
}
@media only screen and (max-width: 767px) {
  .researchImage {
    border-radius: 0rem 0rem 0rem 0rem;
  }
}
.owl-carousel {
  position: relative;
}

.owl-nav {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 100%;
}

.snip {
  width: 90% !important;
  margin-left: 5% !important;
}

.slick-slider {
  width: 83vw !important;
}

.home_products .slick-slider {
  margin-left: 0 !important;
}
.allProductsSlider .slick-slider {
  width: 90% !important;
}
.allProductsSlider .slick-slider .slick-prev,
.allProductsSlider .slick-slider .slick-next {
  background: crimson;
  border-radius: 100%;
}

.home_products .slick-slider .slick-prev:before,
.home_products .slick-slider .slick-next:before {
  color: black !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

/* All products */
.searchBar {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .searchBar {
    width: 100%;
  }
}
