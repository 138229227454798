@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,800);

figure.snip1192 {
  font-family: "Raleway", Arial, sans-serif;
  position: relative;
  overflow: hidden;
  overflow-x: hidden !important;
  scroll-margin-right: none;
  overflow-y: hidden;
  margin-top: 10px;
  /* width: 30%; */
  width: 100%;
  min-height: 9rem;
  color: #333;
  text-align: left;
  box-shadow: none !important;
}

@media only screen and (max-width: 800px) {
  figure.snip1192 blockquote {
    margin: 0;
    display: block;
    border-radius: 8px;
    position: relative;
    background-color: #fafafa;
    padding: 50px 20px 65px 20px !important;
    font-size: 0.8em;
    font-weight: 500;
    margin: 0 0 -50px;
    line-height: 1.6em;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }
  .snip {
    width: 96% !important;
  }
}

figure.snip1192 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

figure.snip1192 img {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: 100px;
  max-width: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
  display: inline-block;
  z-index: 1;
  position: relative;
}

figure.snip1192 blockquote {
  margin: 0;
  display: block;
  border-radius: 8px;
  position: relative;
  background-color: #fafafa;
  padding: 30px 50px 65px 50px;
  font-size: 0.8em;
  font-weight: 500;
  margin: 0 0 -50px;
  line-height: 1.6em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

figure.snip1192 blockquote:before,
figure.snip1192 blockquote:after {
  font-family: "FontAwesome";
  content: "\201C";
  position: absolute;
  font-size: 50px;
  opacity: 0.3;
  font-style: normal;
}

figure.snip1192 blockquote:before {
  top: 35px;
  left: 20px;
}

figure.snip1192 blockquote:after {
  content: "\201D";
  right: 20px;
  bottom: 35px;
}

figure.snip1192 .author {
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
}

figure.snip1192 .author h5 {
  opacity: 0.8;
  margin: 0;
  font-weight: 800;
}

figure.snip1192 .author h5 span {
  font-weight: 400;
  text-transform: none;
  display: block;
}
